/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'camera-reels-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M6 3a3 3 0 11-6 0 3 3 0 016 0"/><path pid="1" d="M9 6a3 3 0 110-6 3 3 0 010 6"/><path pid="2" d="M9 6h.5a2 2 0 011.983 1.738l3.11-1.382A1 1 0 0116 7.269v7.462a1 1 0 01-1.406.913l-3.111-1.382A2 2 0 019.5 16H2a2 2 0 01-2-2V8a2 2 0 012-2z"/>',
    },
});
